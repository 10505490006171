import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`In some API endpoints, we apply rate limits. If an API endpoint is limited, the response header returns information about your rate limit balance.`}</p>
    <h2>{`Headers`}</h2>
    <p>{`If an API endpoint is limited in the header of the response shows your current rate limit status.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Header`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`X-RateLimit-Limit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of requests you are allowed to make in the time period`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`X-RateLimit-Remaining`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of requests remaining in the current time period`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`X-RateLimit-Reset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time at which the current rate limit usage resets (Unix timestamp)`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Limit Exceeded`}</h2>
    <p>{`If the limit is reached, an `}<inlineCode parentName="p">{`HTTP 429 Too Many Requests`}</inlineCode>{` error response is returned. The response header will contain the limit information in this case too.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      